import { useState, useEffect } from 'react';
import './sections.scss'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import TextField from "@mui/material/TextField";
import styled from "styled-components";
import Select from "react-select";


import deleteIcon from "../../../../../assets/images/delete-question.svg"	
import deleteGrayIcon  from "../../../../../assets/images/delete-question-grey.svg";

import editIcon from "../../../../../assets/images/editable.svg";
import viewIcon from "../../../../../assets/images/eye-grey.svg";
import addExternallyIcon from "../../../../../assets/images/add-external.svg";

import QuestionBankPopup from '../../Popups/QuestionBankPopup/QuestionBankPopup';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import LaunchIcon from '@mui/icons-material/Launch';
import emptyBox from '../../../../../assets/images/empty-box.svg'
import EditExternalQuestionTechnicallPopUp from "../../Popups/ExternalQuestionFormPopUp";
import Button from "@mui/material/Button";
import SelectTopicPopup from '../../Popups/SelectTopicPopup';

import { styled as styled_mui } from "@mui/material/styles";
import cogoToast from "cogo-toast";

const CustomButton = styled_mui(Button)({
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    textTransform: "capitalize",
    fontSize: "13px",
    letterSpacing: "0.005em",
    background: "#fff",
    border: "1px solid #3d3d3d",
    borderRadius: "6px",
    color: "#3d3d3d",
    "&:hover": {
        background: "#fff",
        borderRadius: "6px",
        color: "#3d3d3d",
    },
});

const FormSelect = styled(Select)`
  display: inline-block;
  padding: 0;
  border-radius: 4px;
  width: 80px !important;
  align-content: center;
  > div {
    margin-left: 0;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

const getDefaultQuestionCount = () => {
    let count = 20;
    let arr = Array(count);
    arr = Array.from({length: count+1}, (_, index) => ({label:index?.toString(),value:index?.toString()}));
    return arr;
}

const QuestionSet = ({
    interviewPrescreenData,
    setInterviewPrescreenData,
    getQuestionByFilters,
    technicalQuestionList,
    setTechnicalQuestionList,
    originalQuestionList,
    allowedTags,
    setAllowedTags,
    selectedQuestion,
    setSelectedQuestion,
    checkIsPresent,
    role_name,
    category_name,
    pageCount,
    currentPage,
    setCurrentPage,
    questionPerPage,
    totalQuestion,
    searchText,
    setSearchText,
    questionTypeList,
    defaultMarkingList,
    topicList,
    setTopicList,
    showTopicBox,
    setShowTopicBox,
    uploadFileV2,
    skillListOptions,
    fileUpload,
}) => {

    const [formStatus, setFormStatus] = useState('ADD');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editExternalQuestionIndex, setEditExternalQuestionIndex] = useState();
    const [openQuestionBank, setOpenQuestionBank] = useState(false);
    const [selectTopicModal,setSelectTopicModal] = useState({
        status:false,
        selectedTopic:null,
    });


    const addPreviousData = () => {
        const selectedData = [];
        interviewPrescreenData?.questions?.forEach((item) => {
            if (item?.origin !== 'EXTERNAL') {
                selectedData?.push({
                    isChecked: true,
                    question: item,
                    questionId: item?.pre_screening_question_bank_id,
                });
            }
        });
        if (selectedData?.length > 0) {
            setSelectedQuestion((prev) => {
                return [...prev, ...selectedData];
            });
        }
    };

    useEffect(() => {
        if(!selectedQuestion || selectedQuestion?.length === 0)
        addPreviousData();
    }, [interviewPrescreenData]);

    const clearPreviousSelected = () => {
        const tempData = interviewPrescreenData?.questions?.filter(item => item?.origin !== 'QUESTION_BANK')
        return tempData;
    }

    const addTechnicalQuestions = () => {
        if (selectedQuestion && selectedQuestion?.length > 0) {
            const copyData = { ...interviewPrescreenData };
            const temp = clearPreviousSelected();
            copyData.questions = temp;
            selectedQuestion?.forEach(item => {
                if (item?.isChecked) {
                    item.question.isFromQuestionBank = true;
                    item.question.pre_screening_question_bank_id = item?.questionId;
                    item.question.origin = 'QUESTION_BANK';
                    item.question.skill = item?.question?.skill?.id || item?.question?.skill; 
                    copyData.questions.push(item?.question);
                }
                else 
                {
                    item.question.topic = null;
                }
            });
            setInterviewPrescreenData(copyData);
        }
    };

    const renderQuestionRow = (data, index, deleteQuestion) => {

        const editExternalQuestion = (e, index) => {
            setEditModalOpen(true);
            setFormStatus('EDIT');
            setEditExternalQuestionIndex(index)
        }

        const viewQuestion = (e, index) => {
            setEditModalOpen(true);
            setFormStatus('VIEW');
            setEditExternalQuestionIndex(index);
        }

        return (
            <Box display='flex' alignItems='center' className='mt-10'>
                <Box display='flex' flexDirection='column' className='question-bg'>
                    <Typography className='question-title d-flex ql-editor quill-box'> <span className='bold'> Q.&nbsp;</span><span dangerouslySetInnerHTML={{__html: data?.question_text || ""}} ></span></Typography>
                    {/* <Typography className='question-statement'>{`${data?.question?.statement}`}</Typography> */}

                    <Box display='flex' justifyContent={'space-between'}>

                        <Typography className='answer-title mt-1'>
                            <span className='difficulty'>{data?.difficulty_level?.toUpperCase()}</span>
                            {data?.positive_mark && <><span> &#8226; </span> <b>{data?.positive_mark}</b> Point</>}
                            {data?.negative_mark && <><span> &#8226; </span> <b>{data?.negative_mark}</b> Negative marking</>}
                            {data?.tags && data?.tags?.length > 0 && <><span> &#8226; </span> {data?.tags?.join(", ")}</>}
                        </Typography>
                        {data?.origin === 'EXTERNAL' &&
                            <Typography variant='string' className='externally-added' sx={{ fontSize: '10px' }}><LaunchIcon sx={{ transform: 'rotate(180deg)', fontSize: '15px' }} /> Externally Added</Typography>
                        }
                    </Box>
                </Box>
                {data?.origin === 'EXTERNAL' && <img src={editIcon} className='ml-10 pointer' onClick={(e) => editExternalQuestion(e, index)} />}
                {data?.origin !== 'EXTERNAL' && <img src={viewIcon} className='ml-10 pointer' onClick={(e) => viewQuestion(e, index)} />}
                <img src={deleteIcon} alt='delete' className='ml-10 pointer' onClick={() => deleteQuestion(index)} />

            </Box>
        )
    }
    const renderQuestions = () => {
        const addedQuestionBankList = [...interviewPrescreenData?.questions];
        
        const topicwiseQuestionList = [];
        const othersQuestionList = [];
        
       
        const allTopic = topicList?.map(topic=>topic?.topicName);
        let tempList = new Map();
        addedQuestionBankList?.forEach((item,index)=>{
            if(allTopic?.includes(item?.topic))
            {
                if(!tempList?.has(item?.topic))
                {
                    tempList.set(item?.topic,[]);
                }
                let temp = tempList?.get(item?.topic);
                temp?.push({question:item,originIndex:index});
                tempList.set(item?.topic,temp);
            }     
            else
            {
                othersQuestionList?.push({
                    question:item,
                    originIndex:index
                });
            }   
        })

      
        tempList?.forEach((item,topic)=>{
            topicwiseQuestionList?.push({
                topic,
                question:item,
            })
        })

        const deleteQuestion = (index) => {
            const queType = interviewPrescreenData?.questions[index]?.origin;
            if (queType === 'EXTERNAL') {
                const copyData = { ...interviewPrescreenData };
                copyData?.questions.splice(index, 1);
                setInterviewPrescreenData(copyData)
            } else {
                const myId = interviewPrescreenData?.questions[index]?.pre_screening_question_bank_id;
                selectedQuestion?.forEach(item => {
                    if (item?.question?.id === myId) {
                        item.isChecked = !item.isChecked;
                    }
                });
                const copyData = { ...interviewPrescreenData };
                copyData?.questions.splice(index, 1);
                setInterviewPrescreenData(copyData);
                const copySelectedQuestionData = [...selectedQuestion];
                copySelectedQuestionData.splice(index, 1)
                setSelectedQuestion(copySelectedQuestionData);

            }
        }

        return (<Box display='flex' flexDirection='column'>

            <Typography className="heading-others">Added Questions List ({addedQuestionBankList?.length})</Typography>
            {getValidTopics()?.length > 0 ? 
            <>  
                {
                    topicwiseQuestionList?.length > 0 ?
                    <>
                        {
                            topicwiseQuestionList?.map((data)=>{
                                return (
                                    <Box>
                                        <Typography marginTop="12px" className='topic-text'>{data?.topic}</Typography>
                                        <>
                                        {data?.question?.map((item) => {
                                            return renderQuestionRow(item?.question, item?.originIndex, deleteQuestion);
                                        })}
                                        </>
                                    </Box>
                                )
                            })
                        }
                    </>
                    : null
                }

                {othersQuestionList?.length > 0 ?
                    <>
                        <Typography marginTop="12px" className='topic-text'>Generic Questions</Typography>
                        {othersQuestionList?.map((item) => {
                            return (
                                <Box>
                                    {renderQuestionRow(item?.question, item?.originIndex, deleteQuestion)}
                                </Box>
                            );
                            })
                        }
                    </>
                : null}
            </>
            : null
            }
            <>
                {getValidTopics()?.length === 0 && addedQuestionBankList?.map((data, index) => {
                    return renderQuestionRow(data, index, deleteQuestion);
                })}

            </>

            {addedQuestionBankList?.length === 0 &&
                <Box className="noQuestions">
                    <img src={emptyBox} id="emptyBox" />
                    <Typography id="bannerText"><span id="bannerSpan">No questions</span> added yet for this section</Typography>
                </Box>
            }
        </Box>
        )
    }

    const handleQuestionBankOpen = () => {
        if(topicList && topicList?.length > 0)
        {
            selectTopicModal.status = true;
            selectTopicModal.selectedTopic = null;
            setSelectTopicModal({...selectTopicModal});
        }
        else
            setOpenQuestionBank(true);
    }
    
    const renderQuestionBank = () => {
        return (
            <Box display='flex' flexDirection='column' className='mr-20'>
                <QuestionBankPopup
                    getQuestionByFilters={getQuestionByFilters}
                    interviewPrescreenData={interviewPrescreenData}
                    setInterviewPrescreenData={setInterviewPrescreenData}
                    type="technical"
                    questionList={technicalQuestionList}
                    setQuestionList={setTechnicalQuestionList}
                    originalQuestionList={originalQuestionList}
                    allowedTags={allowedTags}
                    setAllowedTags={setAllowedTags}
                    selectedQuestion={selectedQuestion}
                    setSelectedQuestion={setSelectedQuestion}
                    saveSelectedQuestions={addTechnicalQuestions}
                    role_name={role_name}
                    category_name={category_name}
                    totalQuestion={totalQuestion}
                    pageCount={pageCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    questionPerPage={questionPerPage}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    open={openQuestionBank}
                    setOpen={setOpenQuestionBank}
                    handleClickOpen={handleQuestionBankOpen}
                    selectTopicModal={selectTopicModal}
                    skillListOptions={skillListOptions}
                />
            </Box>
        )
    }

    const handleChangedInterviewPrescreenData = (value) => {
        const copyData = { ...interviewPrescreenData };
        copyData.questions = [...value?.questions];
        setInterviewPrescreenData(copyData)

    }

    const openAddQuestionForm = () => {
        setFormStatus('ADD');
        setEditModalOpen(true);
    }

    const isValidTopics = () => {
        let isValid = true;
        topicList?.forEach(topic=>{
            if(!topic?.topicName && topic?.topicName?.trim()?.length === 0)
            {   
                isValid = false;
            }
        });
        let dup = new Set(topicList?.map(topic=>topic?.topicName?.trim()))?.size !== topicList.length;
        if(!isValid)
        {
            cogoToast?.error("Please type a valid topic name before adding a new one",{position:"top-right"})
        }
        if(dup)
        {
            isValid = false;
            cogoToast?.error("Duplicate topic not allowed",{position:"top-right"});
        }
        
        return isValid;
    }

    const handleAddTopic = () => {
        if(!isValidTopics())
            return

        if(!topicList || topicList.length === 0)
        {
            setShowTopicBox(true);
        }

            const copyTopicList = [...topicList];
            copyTopicList.push({
                topicName:"",
                topicCount:0,
            });
            setTopicList(copyTopicList);
    }

    const handleTopicChange = (e,index,type) => {
        const copyTopicList = [...topicList];
        if(type === "name")
        {
            copyTopicList[index].topicName = e.target.value;
        }
        else if(type === "count")
        {
            copyTopicList[index].topicCount = e.value;
        }
        setTopicList(copyTopicList);
    }

    const deleteTopic = (index) => {
        const copyTopicList = [...topicList];
        copyTopicList.splice(index,1)
        setTopicList(copyTopicList);
    }

    const getValidTopics = () => {
        return topicList?.filter(item=>item?.topicName && item?.topicName?.length>0)
    }

    return (

        <Box display='flex' flexDirection='column' className='prescreen-form'>
            <Typography className='header'> Question Set </Typography>

            <Box display='flex' flexDirection='column' className='tinted-background'>


                <Box className="fs-12 grey-text mb-10 mt-r-5">
                    <InfoOutlinedIcon sx={{ fontSize: 12, marginRight: "3px" }} /> Number of questions to be asked on the test is set to <span className="fw-700">{interviewPrescreenData?.number_of_questions || 10} questions.</span> </Box>

                <Box className="fs-12 grey-text mb-10 mt-r-5"><InfoOutlinedIcon sx={{ fontSize: 12, marginRight: "3px" }} />For best-case scenario, we suggest you to add atleast <span className="fw-700">{interviewPrescreenData?.number_of_questions ? interviewPrescreenData?.number_of_questions+10 : 20} questions.</span>
                    <Tooltip title={<Box className="fs-13 padding-10"><Box className="mb-10">Enter at least {interviewPrescreenData?.number_of_questions ? interviewPrescreenData?.number_of_questions+10 : 20} questions</Box>
                        To ensure minimal no. of identical test.</Box>} placement="top">
                        <span className="blue-text">Why?</span>
                    </Tooltip>
                </Box>

                <Box className='fs-12 grey-text mb-10 mt-r-5 question-topic-main-container'>
                {
                    showTopicBox? 
                    <Box display="flex" flexDirection="column" gap="8px" marginBottom="4px">
                        <Box display="flex" gap="4px">
                            <Typography className='head-text' width="250px">Question Topic</Typography>
                            <Typography className='head-text' paddingLeft="12px">No. of questions to ask</Typography>
                        </Box>
                        {
                            topicList?.map((item,index) =>{
                                return (
                                <Box display="flex" gap="16px">
                                    <Box width="250px">
                                    <TextField
                                        id="topic-textfield"
                                        variant="outlined"
                                        placeholder=""
                                        sx={{
                                            padding: "0px",
                                            "& legend": { display: "none" },
                                        }}
                                        size="small"
                                        value={item?.topicName}
                                        onChange={(e)=>handleTopicChange(e,index,"name")}
                                        />
                                    </Box>
                                    <Box display="flex" alignItems="center" gap="8px">
                                        <Box>
                                        <FormSelect
                                            className="custom-history-input"
                                            options={getDefaultQuestionCount()}
                                            placeholder="0"
                                            isSearchable
                                            value={{label:item?.topicCount,value:item?.topicCount}}
                                            onChange={(e)=>handleTopicChange(e,index,"count")}
                                        />
                                        </Box>
                                        <Box>
                                            <img onClick={()=>deleteTopic(index)} className='delete-icon' src={deleteGrayIcon}/>
                                        </Box>
                                    </Box>
                                </Box>
                                )
                            })
                        }
                    </Box> : null
                    }

                    <Typography onClick={handleAddTopic} className='add-topic-text'>
                    + Add Question Topic
                    </Typography>
                </Box>

                <Box display='flex' alignItems={'center'} className="fs-12 grey-text mb-10 mt-r-5">
                    {renderQuestionBank()}

                    <CustomButton className='ml-1' onClick={(e) => openAddQuestionForm()}>
                    <img src={addExternallyIcon}/>
                        &nbsp; Add Externally
                    </CustomButton>

                </Box>


                {renderQuestions()}

                {editModalOpen &&
                    <EditExternalQuestionTechnicallPopUp
                        formStatus={formStatus}
                        editModalOpen={editModalOpen}
                        setEditModalOpen={setEditModalOpen}
                        questionData={interviewPrescreenData}
                        editExternalQuestionIndex={editExternalQuestionIndex}
                        submitChange={handleChangedInterviewPrescreenData}
                        questionTypeList={questionTypeList}
                        defaultMarkingList={defaultMarkingList}
                        topicList={getValidTopics()}
                        uploadFileV2={uploadFileV2}
                        skillListOptions={skillListOptions}
                        fileUpload={fileUpload}
                    />
                }

            <SelectTopicPopup
                selectTopicModal={selectTopicModal}
                setSelectTopicModal={setSelectTopicModal}
                setOpenQuestionBank={setOpenQuestionBank}
                topicList={getValidTopics()}
                />


            </Box>
        </Box>
    )
}
export default QuestionSet;
